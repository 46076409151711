














import currencyFilter from "@/filters/currency.filter";
import dateFormat from "@/filters/date.filter";
import { TruckingAccountReceivableFormStatusDetailInvoiceState } from "@/models/interface/trucking-account-receivable";
import Vue from "vue";

export default Vue.extend({
  name: "FormTruckingInvoiceStatusView",
  props: {
    data: {
      required: true,
      type: Array as () => TruckingAccountReceivableFormStatusDetailInvoiceState[],
    },
  },
  data() {
    return {
      columns: [
        {
          title: this.$t("lbl_invoice_date"),
          dataIndex: "invoiceDate",
          customRender: (text: string) => dateFormat(text),
        },
        {
          title: this.$t("lbl_invoice_number"),
          dataIndex: "invoiceNumber",
        },
        {
          title: this.$t("lbl_amount"),
          dataIndex: "amount",
          customRender: (text: number) => currencyFilter(text),
        },
      ],
    };
  },
});
