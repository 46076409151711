


































import { useBlob, useInvoiceAR, useLocalFilter } from "@/hooks";
import { Option } from "@/models/class/option.class";
import { PrintTitleRequestDto } from "@/models/interface/invoice.interface";
import printJS from "print-js";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {
    VNodes: {
      functional: true,
      render: (h, ctx) => ctx.props.vnodes,
    },
  },
})
export default class InvoicePrintModal extends Vue {
  @Prop({ default: false, type: Boolean, required: true })
  value!: boolean;

  @Prop({ default: "", type: String, required: true })
  docId!: string;

  selectedTitle = "INVOICE";
  searchStr = ""; // state to save new item if doesnt exist

  useLocalFilter = useLocalFilter;

  loading = {
    print: false,
    title: false,
  };

  options: Option[] = [];

  created(): void {
    this.fetchPrintTitle();
  }

  close(): void {
    this.$emit("input", !this.value);
  }

  handlePrint(): void {
    const { toObjectUrl } = useBlob();
    const { print } = useInvoiceAR();
    this.loading.print = true;
    print(this.docId, { title: this.selectedTitle })
      .then(res => {
        printJS(toObjectUrl(res));
      })
      .finally(() => {
        this.loading.print = false;
      });
  }

  fetchPrintTitle(): void {
    const { findAllPrintTitle } = useInvoiceAR();
    this.loading.title = true;
    findAllPrintTitle()
      .then(res => {
        this.options = res.map(item => ({
          label: item.name,
          value: item.name,
          key: item.id,
        }));
      })
      .finally(() => {
        this.loading.title = false;
      });
  }

  addItem(): void {
    const { addPrintTitle } = useInvoiceAR();
    const payload: PrintTitleRequestDto = {
      name: this.searchStr,
    };
    this.loading.title = true;
    addPrintTitle(payload)
      .then(() => {
        this.fetchPrintTitle();
      })
      .finally(() => {
        this.loading.title = false;
      });
  }

  onSearch(e: string | null): void {
    this.searchStr = e || "";
  }
}
