var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("a-table", {
        attrs: {
          "data-source": _vm.data,
          columns: _vm.columns,
          size: "small",
          pagination: false
        }
      }),
      _c("div", { staticClass: "text-right" }, [
        _vm._v(
          " " +
            _vm._s(_vm.$t("lbl_total_items", { total: _vm.data.length })) +
            " "
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }