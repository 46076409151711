var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: { visible: _vm.value },
      on: { cancel: _vm.close },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c(
                "a-button",
                {
                  attrs: { type: "primary", loading: _vm.loading.print },
                  on: { click: _vm.handlePrint }
                },
                [_vm._v(" " + _vm._s(_vm.$t("lbl_print")) + " ")]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "a-form-model-item",
        { attrs: { label: _vm.$t("lbl_title") } },
        [
          _c("a-select", {
            staticClass: "w-100",
            attrs: {
              loading: _vm.loading.title,
              "show-search": "",
              "filter-option": _vm.useLocalFilter,
              "option-filter-prop": "children",
              options: _vm.options
            },
            on: { search: _vm.onSearch },
            scopedSlots: _vm._u([
              {
                key: "dropdownRender",
                fn: function(menu) {
                  return _c(
                    "div",
                    {},
                    [
                      _c("v-nodes", { attrs: { vnodes: menu } }),
                      _c("a-divider", { staticStyle: { margin: "4px 0" } }),
                      _c(
                        "div",
                        {
                          staticStyle: {
                            padding: "4px 8px",
                            cursor: "pointer"
                          },
                          on: {
                            mousedown: function(e) {
                              return e.preventDefault()
                            },
                            click: _vm.addItem
                          }
                        },
                        [
                          _c("a-icon", { attrs: { type: "plus" } }),
                          _vm._v(" " + _vm._s(_vm.$t("lbl_add")) + " ")
                        ],
                        1
                      )
                    ],
                    1
                  )
                }
              }
            ]),
            model: {
              value: _vm.selectedTitle,
              callback: function($$v) {
                _vm.selectedTitle = $$v
              },
              expression: "selectedTitle"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }